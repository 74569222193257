import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useIntl, Link } from "gatsby-plugin-intl";
import robot from '../images/robot.svg';
import '../styles/error-page.scss';


const NotFoundPage = (props) => {
const intl = useIntl();
return(
<Layout location={props.location}>
    <SEO title={`404: ${intl.formatMessage({ id: "notfound.header" })}`} />
    <div className="container-error">
        <div className="container-img">
            <img src={robot} alt={'illustration robot 404'}/>
        </div>
        <div className="bubble">
            <h1>{intl.formatMessage({ id: "notfound.title" })}</h1>
            <p>{intl.formatMessage({ id: "notfound.fail" })}</p>
            <p>{intl.formatMessage({ id: "notfound.find" })}</p>
            <Link to="/" className="btn" title={intl.formatMessage({ id: "notfound.back_home" })}>{intl.formatMessage({ id: "notfound.back_home" })}</Link>
        </div>
    </div>
</Layout>
)
 
}

export default NotFoundPage
